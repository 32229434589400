import { Breadcrumb } from "antd"
import { Content } from "antd/lib/layout/layout"
import Title from "antd/lib/typography/Title"
import { Link } from "gatsby"
import React, { useState } from "react"
import ListNews from "./listNews"
// import Commercial from "../../customComponents/companyInfo/commercial"

const SuperAdNews = ({ location }) => {
  const [title, setTitle] = useState("ニュース管理")

  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item route="/home">
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="!text-[#888888]">
              ニュース管理
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="flex items-center justify-between">
            <Title
              level={3}
              style={{
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              {title}
            </Title>
          </div>
          <div>
            <ListNews location={location} />
          </div>
        </div>
      </Content>
    </div>
  )
}
export default SuperAdNews
