import React, { useEffect, useRef, useState } from "react"
import { Button, Space, Table } from "antd"
import { defineTableListNews } from "./defineTableListNews"
import { navigate } from "gatsby"
import queryString from "query-string"

import { getNewsListData } from "../../../services/news"
import { paginationCustom } from "../../../components/paginationCustom"

// import "../style.scss"

function ListNews({ location }) {
  const [isLoading, setIsLoading] = useState(false)
  const [listData, setListData] = useState([])
  const [total, setTotal] = useState(0)

  const [tableParams, setTableParams] = useState(null)

  const fetchDataForTable = async () => {
    setIsLoading(true)

    const dataSent = {
      limit: tableParams.pagination?.pageSize,
      offer:
        (tableParams.pagination.current - 1) * tableParams.pagination.pageSize,
    }

    const { response } = await getNewsListData(dataSent)
    console.log("responseresponse", response)

    if (response.status == 200) {
      const query = queryString.parse(location.search)
      if (
        response.data.count &&
        Math.ceil(response.data.count / 10) < Number(query?.pageCurrent)
      ) {
        const newQueryStr = queryString.stringify({
          ...query,
          pageCurrent: 1,
        })
        navigate(
          `/super-ad-news/?${queryString.stringify(newQueryStr)}`,
          undefined,
          {
            shallow: false,
          }
        )
        return
      }
      setIsLoading(false)
      setListData(response.data.news)
      setTotal(response.data.count)
    }
  }
  const columns = defineTableListNews(fetchDataForTable)

  const handleTableChange = (pagination, filters, sorter) => {
    const query = queryString.parse(location.search)
    const newQueryStr = {
      ...query,
      pageCurrent: pagination.current,
    }
    delete newQueryStr.sorterField
    delete newQueryStr.order

    if (sorter.order) {
      newQueryStr.sorterField = sorter.field
      newQueryStr.order = sorter.order
    }
    navigate(
      `/super-ad-news/?${queryString.stringify(newQueryStr)}`,
      undefined,
      {
        shallow: false,
      }
    )
  }
  useEffect(() => {
    if (tableParams) {
      fetchDataForTable()
    }
  }, [tableParams])

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (
      Number(query?.pageSize) &&
      Number(query?.pageCurrent) &&
      query?.pageSize == "10"
    ) {
      setTableParams({
        pagination: {
          current: Number(query?.pageCurrent),
          pageSize: 10,
        },
      })
    } else {
      const newQueryStr = queryString.stringify({
        pageSize: 10,
        pageCurrent: 1,
      })
      navigate(`/super-ad-news/?${newQueryStr}`)
    }
  }, [location])
  return (
    <div
      style={{
        width: "100%",
        padding: "16px 0px",
        background: "#FFFFFF",
        color: "#222222",
        borderRadius: "6px",
      }}
    >
      <div
        style={{
          background: "#F3F3F3",
          padding: 10,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          style={{
            height: 30,
            width: 200,
            fontSize: 12,
            background: "#ECB427",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
          onClick={() => {
            navigate("/super-ad-news/create-news")
          }}
        >
          {"＋ 新規投稿"}
        </Button>
      </div>
      {tableParams && (
        <Table
          columns={columns}
          dataSource={listData}
          loading={isLoading}
          rowKey="id"
          onChange={handleTableChange}
          pagination={{
            ...tableParams.pagination,
            total: total,
            itemRender: paginationCustom,
            showSizeChanger: false,
            showTotal: (total, range) => (
              <div>
                {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                {total}件)
              </div>
            ),
          }}
        />
      )}
    </div>
  )
}

export default ListNews
