import { Button, Popconfirm, Space, Switch } from "antd"
import moment from "moment"
import React, { useState } from "react"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../components/notification"
import { deleteNews, updateStatusNews } from "../../../services/news"
import { navigate } from "gatsby"
import textAndRules from "../../../utils/textAndRules"

export const defineTableListNews = (fetchDataForTable) => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  return [
    {
      title: <div style={{ fontSize: 12 }}>{"日付"}</div>,
      width: 200,
      render: (_, record) => {
        return (
          <div>
            {record?.schedule
              ? moment(record?.schedule).format("l HH:mm")
              : "-"}
          </div>
        )
      },
    },
    {
      title: <div style={{ fontSize: 12 }}>{"カテゴリ"}</div>,
      width: "100px",
      render: (_, record) => {
        return (
          <div
            style={{
              borderRadius: "3px",
              background:
                (record.news_type == "news" && "#F0F5FF") ||
                (record.news_type == "notification" && "#FCEFDB") ||
                "unset",
              textAlign: "center",
            }}
          >
            {record.news_type == "news" && "ニュース"}
            {record.news_type == "notification" && "お知らせ"}
          </div>
        )
      },
    },
    {
      title: <div style={{ fontSize: 12 }}>{"タイトル"}</div>,
      dataIndex: "title",
    },
    {
      title: (
        <div style={{ fontSize: 12 }} className="w-full text-center">
          {"表示"}
        </div>
      ),
      width: 100,
      render: (_, record) => {
        return (
          <div>
            <Switch
              checked={
                (record.news_status == "draft" && false) ||
                (record.news_status == "post" && true) ||
                false
              }
              onChange={async (e) => {
                console.log(e)

                const { response }: any = await updateStatusNews(record.id, {
                  news_status: e ? 1 : 0,
                })
                if (response.status == 200) {
                  openNotificationChangeSuccess(
                    textAndRules.updateSuccess,
                    "",
                    "#389e0d"
                  )
                  fetchDataForTable()
                } else {
                  openNotificationFail(
                    "ただいま、サーバが混み合っています。",
                    "しばらくしてから再度操作してください。",
                    "#f81d22"
                  )
                }
              }}
            />
          </div>
        )
      },
    },
    // {
    //   title: <div style={{ fontSize: 12 }}>{"ステータス"}</div>,
    // },
    {
      title: (
        <div style={{ fontSize: 12 }} className="w-full text-center">
          {"操作"}
        </div>
      ),
      width: "100px",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space>
            <Button
              type="primary"
              ghost
              onClick={() => {
                navigate(`/super-ad-news/detail-news/${record.id}`, {
                  state: { ...record },
                })
              }}
            >
              編集
            </Button>
            <Popconfirm
              placement="bottomLeft"
              title={
                <div style={{ fontSize: 14, fontWeight: 600 }}>
                  {`本当に実行しますか ?`}
                </div>
              }
              onConfirm={async () => {
                setIsLoadingDelete(true)
                const { response }: any = await deleteNews(record.id)
                if (response.status == 200) {
                  setIsLoadingDelete(false)

                  fetchDataForTable()
                  openNotificationChangeSuccess(
                    textAndRules.updateSuccess,
                    "",
                    "#389e0d"
                  )
                } else {
                  openNotificationFail(
                    "ただいま、サーバが混み合っています。",
                    "しばらくしてから再度操作してください。",
                    "#f81d22"
                  )
                }
              }}
              okText="削除"
              cancelText="キャンセル"
            >
              <Button loading={isLoadingDelete}>{"削除"}</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]
}
